import { MenuItem } from "./menu.model";

export const MENU: MenuItem[] = [
  {
    id: 1,
    label: "MENUITEMS.MENU.TEXT",
    isTitle: true,
  },
  {
    id: 9,
    label: "Dashboard",
    icon: "bx-home",
    link: "/dashboard",
  },

  {
    id: 36,
    label: "Booked Appointments",
    icon: "bx-book-open",
    link: "/appointment",
  },

  // {
  //   id: 36,
  //   label: "Planning Information - ALL",
  //   icon: "bx-book-open",
  //   link: "/planning-information",
  // },

  {
    id: 36,
    label: "Pending Planning Applications",
    icon: "bx-book-open",
    link: "/planning-information/pending",
  },

  {
    id: 36,
    label: "Planning Application - CHARTING",
    icon: "bx-book-open",
    link: "/planning-information/charting",
  },

  {
    id: 36,
    label: "Approved Planning Applications",
    // label: "Booked Site Inspection",
    icon: "bx-book-open",
    link: "/planning-information/approved",
  },

  {
    id: 36,
    // label: "Planning Information - INSPECTION",
    label: "Pending Site Inspection",
    icon: "bx-book-open",
    link: "/planning-information/inspection",
  },

  {
    id: 36,
    // label: "Planning Information - INSPECTED",
    label: "Completed Site Inspection",
    icon: "bx-book-open",
    link: "/planning-information/inspected",
  },

  // {
  //   id: 36,
  //   label: "Planning Information - COMPLETED",
  //   icon: "bx-book-open",
  //   link: "/planning-information/completed",
  // },

  {
    id: 36,
    label: "Calendar (coming soon)",
    icon: "bx-calendar",
    link: "/dashboard",
  },

  // {
  //   id: 36,
  //   label: "Payments (coming soon)",
  //   icon: "bx-money",
  //   link: "/dashboard",
  // },

  // {
  //   id: 36,
  //   label: "Customers",
  //   icon: "bx-user",
  //   link: "/customers",
  // },

  // {
  //   id: 36,
  //   label: "Documents",
  //   icon: "bx-file",
  //   link: "/documents",
  // },

  // {
  //   id: 36,
  //   label: "Staff",
  //   icon: "bx-user",
  //   link: "/staff",
  // },

  {
    id: 36,
    label: "Settings",
    icon: "bx-cog",
    subItems: [
      {
        id: 37,
        label: "Category",
        link: "/settings/categories",
        parentId: 36,
      },
      {
        id: 38,
        label: "Professions",
        link: "/settings/professions",
        parentId: 36,
      },
      {
        id: 38,
        label: "Locations",
        link: "/settings/locations",
        parentId: 36,
      },
      {
        id: 38,
        label: "Services",
        link: "/settings/services",
        parentId: 36,
      },
    ],
  },

  // {
  //   id: 36,
  //   label: "Support Requests (coming soon)",
  //   icon: "bx-chat",
  //   link: "/dashboard",
  // },
];
